import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS_TRACKS } from "containers/AuthorPage/auther_Page_query";
import {
  LIKE_PROJECT_MUTATION,
  UNLIKE_PROJECT_MUTATION,
} from "DataProvider/Mutations_gql";
import {
  CHECK_PROJECT_IS_LIKED_BY_USER,
  GET_PROJECT_CLAPSCOUNT_Project,
} from "DataProvider/Queries_gql";
import React, { useState } from "react";

export interface LikeButtonProps {
  className?: string;
  liked?: boolean;
  ClapsCount?: number;
  ProjectID?: string;
  disableLike?: boolean;
}

const LikeButton: React.FC<LikeButtonProps> = ({
  className,
  liked,
  ClapsCount,
  ProjectID,
  disableLike,
}) => {
  const [makeRed, setMakeRed] = useState(true);

  const [likeTrack] = useMutation(LIKE_PROJECT_MUTATION);

  const [unlikeProject] = useMutation(UNLIKE_PROJECT_MUTATION);

  const { data: ProjectCountData } = useQuery(GET_PROJECT_CLAPSCOUNT_Project, {
    variables: {
      filter: {
        id: ProjectID,
      },
    },
  });

  const { data: CUClapedData } = useQuery(CHECK_PROJECT_IS_LIKED_BY_USER, {
    variables: {
      projectId: ProjectID,
    },
  });

  const LikeProject = () => {
    setMakeRed(!makeRed);
    console.log("like");
    likeTrack({
      variables: {
        projectId: ProjectID,
      },
      refetchQueries: () => [
        {
          query: CHECK_PROJECT_IS_LIKED_BY_USER,
          variables: {
            projectId: ProjectID,
          },
        },
        {
          query: GET_PROJECT_CLAPSCOUNT_Project,
          variables: {
            filter: {
              id: ProjectID,
            },
          },
        },

        {
          query: GET_USERS_TRACKS,
          variables: {
            filter: {
              status: "PUBLISHED",
              owner: {
                id: `${localStorage.getItem("user_id")}`,
              },
            },
          },
        },
      ],
    });
  };

  const unlikeLikedProject = () => {
    setMakeRed(!makeRed);
    console.log("unlikelike");
    unlikeProject({
      variables: {
        projectId: ProjectID,
      },
      refetchQueries: () => [
        {
          query: CHECK_PROJECT_IS_LIKED_BY_USER,
          variables: {
            projectId: ProjectID,
          },
        },
        {
          query: GET_PROJECT_CLAPSCOUNT_Project,
          variables: {
            filter: {
              id: ProjectID,
            },
          },
        },
        {
          query: GET_USERS_TRACKS,
          variables: {
            filter: {
              status: "PUBLISHED",
              owner: {
                id: `${localStorage.getItem("user_id")}`,
              },
            },
          },
        },
      ],
    });
  };

  const handleButtonClick = () => {
    if (CUClapedData.checkIfProjectIsClapByUser.value) {
      unlikeLikedProject();
    } else LikeProject();
  };

  return (
    <button
      disabled={disableLike}
      title={disableLike ? "Unable to like!" : ""}
      className={`px-3.5 h-12 flex items-center justify-center bg-black/50 text-white rounded-full shadow-2xl ${className}`}
      onClick={handleButtonClick}
    >
      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
        <path
          d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
          stroke="#fff"
          fill={CUClapedData?.checkIfProjectIsClapByUser.value ? "#ef4444" : ""}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="ml-2 text-sm">
        {ProjectCountData?.Project.clapsCount}
      </span>
    </button>
  );
};

export default LikeButton;
