import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
  query AllProjects(
    $take: Int
    $orderBy: ProjectOrderByInput
    $filter: ProjectWhereInput
  ) {
    allProjects(take: $take, orderBy: $orderBy, filter: $filter) {
      id
      name
      status
      videoExist
      headerImage
      mixdownAudio
      mixdownVideo
      mixdownPath
      owner {
        id
        name
        role
        artistName
        avatarUrl
        fcm_token
      }
      description
      commentsCount
      clapsCount
      tracksCount
      comments {
        id
        owner {
          id
          email
          username
          name
          artistName
          avatarUrl
          fcm_token
        }
        project {
          id
          name
        }
        text
        atTimeMicros
        createdAt
      }
      claps {
        owner {
          id
          fcm_token
        }
      }
      tracks {
        id
        originalOwner {
          id
          name
          avatarUrl
          fcm_token
          __typename
        }
        recordings {
          id
        }
      }
    }
  }
`;

export const GET_HOMESCREEN = gql`
  query PlaylistPlayer($where: PlaylistWhereUniqueInput!) {
    data: playlistPlayer(where: $where) {
      id
      orderedProjects {
        project {
          id
          name
          headerImage
          mixdownAudio
          mixdownVideo
          owner {
            id
            name
            artistName
            avatarUrl
            fcm_token
          }
          description
          clapsCount
          description
        }
      }
    }
  }
`;

export const GET_LATEST_CONTESTS_ADITIONS = gql`
  query ContestProjects(
    $take: Int
    $orderBy: ContestProjectOrderByInput
    $where: ContestProjectWhereInput
  ) {
    contestProjects(take: $take, orderBy: $orderBy, where: $where) {
      contest {
        title
        __typename
      }
      project {
        mixdownVideo
        mixdownAudio
        mixdownPath
        videoExist
        createdAt
        name
        owner {
          avatarUrl
          artistName
          id
          name
          amIFollowed
          fcm_token
          __typename
        }
        headerImage
        description
        id
        isLikedByMe
        tracks {
          originalOwner {
            name
            artistName
            avatarUrl
            fcm_token
            id
            __typename
          }
          recordings {
            id
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_PROJECT_COMMENTS = gql`
  query getComments($id: ID, $countOfComments: Int) {
    allComments(
      filter: { project: { id: $id } }
      orderBy: createdAt_DESC
      first: $countOfComments
    ) {
      id
      createdAt
      updatedAt
      owner {
        name
        avatarUrl
        id
        fcm_token
        __typename
      }
      project {
        name
        __typename
      }
      text
      atTimeMicros
      __typename
    }
  }
`;

export const AM_I_FOLLOWING_USER = gql`
  query AmIFollowingUser($amIFollowingUserId: ID!) {
    amIFollowingUser(id: $amIFollowingUserId) {
      value
    }
  }
`;

export const CHECK_PROJECT_IS_LIKED_BY_USER = gql`
  query CheckIfProjectIsClapByUser($projectId: ID!) {
    checkIfProjectIsClapByUser(projectId: $projectId) {
      value
    }
  }
`;


export const GET_PROJECT_CLAPSCOUNT_Project = gql`
  query Project($filter: ProjectWhereUniqueInput!) {
    Project(filter: $filter) {
      clapsCount
    }
  }
`;

export const GET_REQ_SONG = gql`
  query ProjectPlayer($filter: ProjectWhereUniqueInput!) {
    ProjectPlayer(filter: $filter) {
      id
      name
      headerImage
      headerVideo
      mixdownVideo
      mixdownAudio
      clapsCount
      description
      owner {
        id
        name
        artistName
        avatarUrl
        fcm_token
      }
    }
  }
`;

export const GET_SINGLE_TRACKS = gql`
  query Track($filter: TrackWhereUniqueInput!) {
    Track(filter: $filter) {
      id
      originalOwner {
        id
        name
        avatarUrl
        fcm_token
        __typename
      }
      __typename
    }
  }
`;

export const GET_HOMESCREEN_WIDGETS = gql`
  query GetHomescreenConfig(
    $orderBy: HomescreenOrderByInput
    $where: HomescreenWhereInput
  ) {
    getHomescreenConfig(orderBy: $orderBy, where: $where) {
      id
      title
      type
      priority
      offset
      contestId
      description
      limit
      status
      playlist {
        id
        name
        __typename
      }
      user {
        id
        __typename
      }
      __typename
    }
  }
`;

export const GET_ALL_CONTEST = gql`
  query AllContests($where: ContestWhereInput) {
    allContests(where: $where) {
      id
      title
      prize
      description
      termsAndConds
      image
      bannerImage
      bannerVideo
      contestVideo
      allowTrackUpload
      startDate
      endDate
      contestMedia {
        id
        stage
        description
        image
        bannerImage
        bannerVideo
        contestVideo
        carouselBanner1
        carouselBanner2
        carouselBanner3
      }
    }
  }
`;

export const GET_CONTEST_IMAGE = gql`
  query Contest($where: ContestWhereInput) {
    allContests(where: $where) {
      id
      title
      prize
      bannerImage
    }
  }
`;
