import React, { useEffect, useId, useRef } from "react";
import SectionLatestContests from "components/SectionLatestContests";
import SectionPopularContests from "components/SectionPopularContests";
import { Tab } from "@headlessui/react";
import { Redirect, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_CONTEST } from "DataProvider/Queries_gql";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import AppStorePng from "images/socials/appstore.png";
import PlayStorePng from "images/socials/playstore.png";

const ContestTabs = ["Latest", "Popular"];

function Index() {
  const params: any = useParams();
  const token = localStorage.getItem("token");
  const contestId: any = params.contestId;

  const { data, loading, error } = useQuery(GET_ALL_CONTEST, {
    variables: { where: { id: contestId } },
  });

  const sliderRef = useRef(null);
  const id = useId();

  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  let carouselarray: any[] = [];
  if (data) {
    carouselarray = [
      data?.allContests[0]?.contestMedia[0]?.carouselBanner1,
      data?.allContests[0]?.contestMedia[0]?.carouselBanner2,
      data?.allContests[0]?.contestMedia[0]?.carouselBanner3,
    ];
  }

  const renderTabItem = (item: string) => {
    switch (item) {
      case "Latest":
        return <SectionLatestContests contestId={contestId} />;

      case "Popular":
        return <SectionPopularContests contestId={contestId} />;

      default:
        return null;
    }
  };

  useEffect(() => {
    const OPTIONS: Glide.Options = {
      perView: 2.5,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.5,
        },
        1024: {
          gap: 20,
          perView: 2.3,
        },
        768: {
          gap: 20,
          perView: 1.5,
        },
        500: {
          gap: 20,
          perView: 1,
        },
      },
    };
    let slider: any;
    if (data) {
      slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
      slider.mount();
    }
    // @ts-ignore
    return () => {
      if (data) {
        slider.destroy();
      }
    };
  }, [sliderRef, UNIQUE_CLASS, data]);

  if (!token) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: `/contest/${params.contestId}` },
        }}
      />
    );
  }

  if (loading) {
    return (
      <div className="container mt-10 flex flex-col mx-auto justify-center items-center">
        <svg
          className={`motion-safe:animate-spin dark:text-white text-black-ml-1 mr-3 h-10 w-10`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <div className="font-thin text-sm mt-4">Loading Contest</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-40 flex flex-col mx-auto justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="animate-ping text-red-700 -ml-1 mr-3 h-10 w-10"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <div className="font-thin text-sm mt-4">Error Fetching Contest</div>
      </div>
    );
  }

  return (
    <>
      {data && (
        <div className="container mt-10 flex flex-col mx-auto justify-center items-center">
          <div className="w-full px-5 sm:px-0 ">
            <Heading desc="" className="mb-0">
              {data?.allContests[0]?.title}
            </Heading>
            <p className="text-xl md:text-xl py-3  italic xl:pr-96">
              {data?.allContests[0].description}
            </p>
          </div>
          <div className="w-full px-5 sm:px-0 flex flex-row">
            <div className="w-min h-16 flex items-center">
              <a
                className="h-36 w-36"
                href="https://apps.apple.com/tt/app/djaminn-create-songs-together/id1634589883"
              >
                <img
                  src={AppStorePng}
                  alt="App Store"
                  className="h-36 w-36 object-contain "
                />
              </a>
            </div>
            <div className="w-min h-16 flex items-center ml-3">
              <a
                className="h-36 w-36"
                href="https://play.google.com/store/apps/details?id=com.djamin"
              >
                <img
                  src={PlayStorePng}
                  alt="Play Store "
                  className="h-36 w-36 object-contain "
                />
              </a>
            </div>
          </div>
          <div className="w-full px-2 mt-5 sm:px-0 ">
            <Heading desc="" className="mb-0">
              Tips
            </Heading>
            <div className={`${UNIQUE_CLASS} flow-root mt-2`} ref={sliderRef}>
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides p-5">
                  {carouselarray.map((item: any, index: any) => {
                    return (
                      <li
                        key={`${"" + index}`}
                        className={`glide__slide rounded-3xl bg-white shadow-lg`}
                      >
                        <img
                          className="object-cover rounded-3xl"
                          alt="object"
                          src={item}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <p className="text-xl md:text-xl py-3 font-Montserrat italic">
              {data?.allContests[0].prize}
            </p>
            <div className="mt-5 lg:mt-5 flex justify-start items-center">
              <img
                className="inset-0 w-3/5 h-3/5 object-cover rounded-[32px]"
                src={data?.allContests[0]?.contestMedia[0]?.image}
                alt="hero"
              />
            </div>
          </div>
          <div className="w-full px-2 my-10 sm:px-0">
            <Tab.Group>
              <Tab.List className="flex justify-start pd-1 space-x-2.5 rounded-full bordedr border-neutral-300 dark:border-neutral-500">
                {ContestTabs.map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      `px-3.5 sm:px-8 py-1.5 sm:py-2 text-xs sm:text-sm leading-5 font-medium rounded-full focus:outline-none focus:ring-2 ring-primary-300 ${
                        selected
                          ? "bg-neutral-900 dark:bg-neutral-100 text-white dark:text-neutral-900 text-xl md:text-xl font-semibold font-Montserrat italic"
                          : "text-neutral-700 dark:text-neutral-300 bg-neutral-100/70 dark:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100 text-xl md:text-xl font-semibold font-Montserrat italic"
                      }`
                    }
                  >
                    {tab}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-4">
                {ContestTabs.map((tab, idx) => (
                  <Tab.Panel key={`${"" + idx}`} className={"rounded-xl"}>
                    {renderTabItem(tab)}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      )}
    </>
  );
}

export default Index;
